import React from 'react';
import instagram from '../images/instagram.png';
import linkedin from '../images/linkedin.png';
import logo from '../images/logo-footer.png';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer" id='bottom'>
            <div className='footer-col-1'>
                <img className="footer-logo" src={logo} alt='Kasb logo' />
                <p>
                تهران، بلوار اشرفی اصفهانی، خیابان شهید قموشی، خیابان بهار دانشگاه علم و فرهنگ، طبقه ۶، پارک ملی علوم،
                فناوری‌های نرم و صنایع فرهنگی
                    <br />
                    <br />
                    تلفن پشتیبانی: 28422140-021
                </p>
                <div className='footer-social-networks'>
                    <a href='https://instagram.com/kasb.co' target="_blank">
                        <img className='footer-img' src={instagram} alt='Instagram' />
                    </a>
                    <a href='https://www.linkedin.com/company/kasb-platform/' target="_blank">
                        <img className='footer-img' src={linkedin} alt='Linkedin' />
                    </a>
                </div>
            </div>
            <div className='footer-col-2'>
                <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=377197&amp;Code=uJ0in2XYu4LpCITYrWol">
                    <img
                        referrerpolicy="origin"
                        src="https://Trustseal.eNamad.ir/logo.aspx?id=377197&amp;Code=uJ0in2XYu4LpCITYrWol"
                        alt="ENMAD logo"
                        className="footer-img footer-enmad"
                        id="uJ0in2XYu4LpCITYrWol"
                    />
                </a>
                {/* TODO: make this work, then uncomment it */}
                {/* <div className="subscribe">
                    <h3>در جریان کسب باشید!</h3>
                    <div className="subscribe-form">
                        <input type='email' placeholder='ایمیل خود را وارد کنید.' required title='لطفا این قسمت را پر کنید.'/>
                        <button>اشتراک</button>
                    </div>
                </div> */}
            </div>
        </footer>
    );
};

export default Footer;
