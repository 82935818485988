import './ReleaseNotesPage.css';
import Header from '../components/Header';
import Content from '../components/release_notes/Content';
import Footer from '../components/Footer';

const ReleaseNotesPage = () => {
    return (
        <div className='release-notes-page-container'>
            <Header />
            <Content/>
            <Footer />
        </div>
    );
}

export default ReleaseNotesPage;
