// Import necessary libraries
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SlideShow.css';
import ScreenShot1 from '../images/screenshot1.png';
import ScreenShot2 from '../images/screenshot2.png';
import ScreenShot3 from '../images/screenshot3.png';
import arrowBackImg from '../images/arrow_back.svg';

// Create the SlideShow component
const SlideShow = () => {
  // Define the slides data
  const slides = [
    {
      title: "فاکتور هوشمند",
      image: ScreenShot1,
      text: "در کسب بدون نیاز به فاکتور کاغذی، امــکان صــدور فاکتور هوشمند و ارسال لینک اختصاصی برای مشتریان شما فراهم شده است. لینک اختصاصی، امکان مشاهده‌ گزارش وضعیت فاکتور از مرحله صدور تا ارسال کالا را فراهم کرده است."
    },
    {
      title: "اطلاعات محصول",
      image: ScreenShot2,
      text: "کسب این امکان را به شما می‌دهد تا محصولات را با مشخصات کامل اعم از مدل، قیمت خرید، قیمت فروش و … ثبت کنید و با استفاده از نمودار گزارش‌دهی، پیش‌بینی دقیقی از بازار داشته باشید."
    },
    {
      title: "اطلاعات به درد بخور",
      image: ScreenShot3,
      text: "با نصب اپلیکیشن کسب می‌توانید کسب‌وکار خود را مدیریت کنید، با نظم دادن به فرایند فروش و دریافت گزارش لحظه‌ای و آنلاین راه را برای پیشرفت فراهم کنید."
    }
  ];

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <img src={arrowBackImg} style={{ width: '32px' }} alt="Next"/>
      </div>
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <img src={arrowBackImg} style={{ width: '32px', rotate: '180deg' }} alt="Previous"/>
      </div>
    );
  }

  // Slider settings
  const settings = {
    draggable:true,
    dots: false,
    rtl: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slideshow-container" id='features'>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <div className="slide-content">
              <div className="slide-image">
                <img src={slide.image} alt={slide.title} />
              </div>
              <div className="slide-text">
                <h2>{slide.title}</h2>
                <p>{slide.text}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlideShow;
