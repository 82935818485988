import logo from '../images/logo-header.png';
import './Navbar.css';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleScroll = (id, path = '/') => {
        navigate(path);
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth'
                });
            }
        }, 100); 
        toggleMenu();
    };

    return (
        <nav className="navbar">
            <RouterLink to='/'>
                <img className="nav-logo" src={logo} alt='Kasb logo' />
            </RouterLink>
            <div className={`nav-items ${isOpen ? 'active' : ''}`}>
                <RouterLink to="/#download" onClick={() => handleScroll('download')}>دریافت</RouterLink>
                <RouterLink to="/#intro" onClick={() => handleScroll('intro')}>معرفی</RouterLink>
                <RouterLink to="/#features" onClick={() => handleScroll('features')}>ویژگی‌ها</RouterLink>
                <RouterLink to="/ronagh" onClick={toggleMenu}>بسته‌ی رونق</RouterLink>
                <RouterLink to="/#faq" onClick={() => handleScroll('faq')}>سوالات متداول</RouterLink>
                <RouterLink to="/#bottom" onClick={() => handleScroll('bottom')}>تماس با ما</RouterLink>
            </div>
            <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </nav>
    );
};

export default Navbar;