import React from 'react';
import './Hero.css';
import HeroImage from '../images/landing-page-hero.png';
import { Link as RouterLink } from 'react-router-dom';
import openInNew from '../images/open_in_new.png';

const Hero = () => {
    return (
        <div className='hero'>
            <img src={HeroImage} alt="Landing page hero" className='heroImage' />
            <h2>نانتان در رونق است!</h2>
            <h3>اپلیکیشن کسب، منظم‌ترین پلتفرم صدور فاکتور</h3>
            <RouterLink to='https://web.kasb-co.com' target='_blank' className="web-app-button">
                <img src={openInNew} alt="WebApp"/>
                ورود به وب‌اپلیکیشن
            </RouterLink>
        </div>
    );
};

export default Hero;
