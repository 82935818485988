import './Content.css';

const Content = () => {
    return (
        <div className="privacy-policy-content-container">
            <h4>کسب چگونه از اطلاعات شما محافظت می‌کند؟</h4>
            <p>امروزه حریم خصوصی کاربران به یکی از جدی‌ترین دغدغه‌ها تبدیل شده‌است. نگرانی بسیاری از کاربران و مشتریان، محفوظ ماندن اطلاعات شخصی همچنین شماره موبایل، نام و نام خانوادگی و ایمیل است که برای ثبت نام و ورد به محیط اپلیکیشن به ما می‌سپارند. ما اهمیت این موضوع را درک می‌کنیم و حریم خصوصی کاربران برای ما اهمیت بسیاری دارد. سیاست حفظ حریم خصوصی و محرمانگی اطلاعات کاربران را به شرح ذیل ملاحظه خواهیدکرد.</p>
            <h4>اطلاعاتی که  توسط کسب از شما دریافت می‌شود شامل چه مواردی است؟</h4>
            <p>اطلاعاتی که برای ایجاد حساب کاربری در نرم‌افزار کسب ضروری است شامل شماره تلفن همراه، و رمز عبور (به صورت رمز‌گذاری‌شده) و  سایر اطلاعاتی که شما در اختیار کسب قرار می‌دهید.</p>
            <p>برای ثبت نام و ایجاد حساب کاربری در اپلیکیشن و وب سایت کسب اطلاعاتی مانند: نام فروشگاه، حوزه‌فعالیت، شماره تلفن ثابت فروشگاه، آدرس ایمیل، آدرس فروشگاه از شما دریافت می‌شود.</p>
            <p>دریافت این مشخصات صرفا برای ثبت نام در اپلیکیشن و درج در فاکتور صادر شده توسط شما برای مشتریانتان، هماهنگی‌های لازم برای ارسال اعلان‌های مهم و مطلع کردن شما از رویدادها و تخفیف‌های ویژه، استفاده از کلیه خدمات داخل اپلیکیشن و وبسایت کسب است.</p>
            <p>لازم به ذکر است دسترسی به گالری تلفن همراه شما با دریافت تاییدیه از شما جهت انتخاب تصویر یا لوگوی فروشگاه شما و امکان ذخیره کردن فاکتور صادر شده توسط شما در گالری تلفن همراه شماست و این دسترسی تنها با اجازه‌ و تایید شما امکان پذیر خواهد بود.</p>
            <p>در صورتی که شما مایل نیستید اطلاعات شما در اختیار کسب قرار گیرد، لطفا از نصب اپلیکیشن کسب  و ایجاد حساب کاربری و ثبت اطلاعات شخصی در سایت و اپلیکیشن کسب خودداری نمایید.</p>
            <h4>نحوه‌ی محافظت از اطلاعات شما چگونه است؟</h4>
            <p>محافظت از اطلاعات حساب کاربران و جلوگیری از دسترسی‌های غیرمجاز به این اطلاعات یکی از الویت‌های تیم کسب  است. با وجود این‌که امنیت به صورت نسبی تعریف شده است اما در طراحی اپلیکیشن و وب سایت  مجموعه کسب سعی شده که از بالاترین سطوح امنیتی سخت‌افزاری و نرم‌افزاری در نظر گرفته شود.</p>
            <p>در زمان ثبت نام تمامی در خواست‌ها و خدمات بر روی سرورهای ایمن ما ذخیره و توسط فایروال‌های مستحکم محافظت خواهند شد. اطلاعات شخصی شما نیز به صورت رمزنگاری‌شده در بستر امن HTTPS به وب‌سایت  ما منتقل و برای تعاملات بعدی نگهداری خواهند شد.</p>
            <p>بسیاری از خدمات کسب با SSL‌ رمزگذاری شده‌.</p>
            <p> برای هر بار ورود به حساب کاربری در کسب از رمز عبور یک‌بار مصرف (OTP) استفاده می‌شود که تنها با دسترسی به شماره تلفن همراهی که از طریق آن در کسب، حساب کاربری خود را ایجاد کرده‌اید امکان‌پذیر است و در صورتیکه رمز عبور توسط کاربر تعریف شده باشد برای ورود از همان رمز عبور استفاده خواهید کرد.</p>
            <p>علی‌رغم تمام رویه‌های ایمنی که کسب مطابق با قانون به هنگام ارائه خدمات اتخاذ کرده است، متاسفانه تدابیر امنیتی را نمی‌توان به صورت صد در صدی تضمین نمود، بنابراین پیشنهاد می‌شود:</p>
            <ul>
                <li> از اطلاعات حساب کاربری خود محافظت کرده و آن‌ها را در اختیار اشخاص ثالث قرار ندهید</li>
                <li>رمز عبوری را انتخاب نمایید که حتی‌الامکان برای دیگران غیر قابل حدس باشد</li>
                <li>در صورت وقوع سرقت گوشی همراه و همچنین پیش از واگذاری آدرس ایمیل و شماره تلفنی که با آن حساب کاربری ایجاد کرده‌اید، موضوع را به کسب اطلاع دهید تا حساب کاربری شما تعلیق شده یا اطلاعات موجود در آن تغییر کند. در غیر این صورت و در موارد دسترسی اشخاص ثالث به رمز عبور شما، تمامی مسئولیت هرگونه استفاده یا سوءاستفاده از حساب کاربری شما به عهدۀ شخص شماست و کسب هیچ‌گونه مسئولیتی در این مورد ندارد.</li>
            </ul>
            <h4>موارد استفاده از اطلاعات شما چیست؟</h4>
            <p>اطلاعات شخصی شما صرفاً جهت تعاملات بعدی به صورت کدگذاری‌شده نگهداری می‌شوند تا در سفارش‌ها و درخواست‌های آینده بتوانید به راحتی وارد اپلیکیشن شوید و از خدماتی که درون اپلیکیشن کسب در اختیار شما قرارداده شده استفاده کنید.</p>
            <h4>چه کسانی به اطلاعات شما دسترسی دارند؟</h4>
            <p>همکاران بخش پشتیبانی و خدمات پس از فروش به برخی از اطلاعات شما دسترسی دارند آن هم برای ارائه پشتیبانی بهتر و خدمات با کیفیت‌تر مورد استفاده قرار می‌گیرد. تمامی اطلاعات شخصی شما نزد ما محفوظ خواهد ماند و در اختیار افراد یا سازمان‌های دیگری قرار نخواهد گرفت. بدیهی است که مراجع قانونی با ارائه حکم دادگاه قادرند به این اطلاعات دسترسی داشته باشند. به طور کلی ما موظف به حفاظت از اطلاعات شما هستیم.</p>
        </div>
    );
}

export default Content;
