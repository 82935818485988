import './PrivacyPolicyPage.css';
import Header from '../components/Header';
import Content from '../components/privacy_policy/Content';
import Footer from '../components/Footer';

const PrivacyPolicyPage = () => {
    return (
        <div className='privacy-policy-page-container'>
            <Header />
            <Content/>
            <Footer />
        </div>
    );
}

export default PrivacyPolicyPage;
