import logo from '../images/logo-header.png';
import './Header.css';
import { Link as RouterLink } from 'react-router-dom';
import homeImg from '../images/fa-home.svg';

const Header = () => {
    return (
        <div className="header">
            <RouterLink to='/'>
                <img className="nav-logo" src={logo} alt='Kasb logo' />
            </RouterLink>
            <RouterLink to='/' className="home-button">
                بازگشت به خانه
                <img src={homeImg} alt="Home" className="home-icon"/>
            </RouterLink>
        </div>
    );
}

export default Header;
