import React from "react";
import './Package.css';

const Package = () => {
    return (
        <div className="package-container">
            <div className="package-header">بسته‌ی رونق</div>
            <div className="package-list">
                <div className="package-price">
                    <div>99,000 <sup>تومان</sup></div>
                    <div>سه ماهه</div>
                </div>
                <div className="package-price">
                    <div>180,000 <sup>تومان</sup></div>
                    <div>شش ماهه</div>
                </div>
                <div className="package-price">
                    <div>350,000 <sup>تومان</sup></div>
                    <div>یک ساله</div>
                </div>
            </div>
            <div className="package">
                <div className="package-features">
                    <div>لیست قیمت</div>
                    <div>جشنواره فروش <sup>(به‌زودی)</sup></div>
                    <div>گزارش پیشرفته <sup>(به‌زودی)</sup></div>
                </div>
            </div>
        </div>
    );
};

export default Package;