import './NotFoundPage.css';
import { Link as RouterLink } from 'react-router-dom';
import homeImg from '../images/fa-home.svg';

const NotFoundPage = () => {
  return (
    <div className="not-found-page-container">
      <h1>404</h1>
      <h4 className="not-found-h4">صفحه مورد نظر یافت نشد</h4>
      <RouterLink to='/' className="home-button">
          بازگشت به خانه
          <img src={homeImg} alt="Home" className="home-icon"/>
      </RouterLink>
    </div>
  )
}

export default NotFoundPage;
