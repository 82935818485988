import React, { useEffect, useRef } from 'react';
import './VideoPlayerModal.css';

const VideoPlayerModal = ({ show, close }) => {
    const videoContainerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.aparat.com/embed/RrZOT?data[rnddiv]=58687588900&data[responsive]=yes&titleShow=true&recom=self";
        script.type = "text/javascript";
        script.async = true;
        videoContainerRef.current.appendChild(script);
    }, []);

    return (
        <div className="modal-overlay" style={{ visibility: show ? 'visible' : 'hidden' }} onClick={close}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div ref={videoContainerRef} id="58687588900"></div>
                <p className="close-button" onClick={close}>بستن</p>
            </div>
        </div>
    );
};

export default VideoPlayerModal;
