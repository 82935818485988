import './Content.css';

const Content = () => {
    return (
        <div className="terms-and-conds-content-container">
            <h3>قوانین و مقررات (Terms and Conditions)</h3>
            <h4>مقررات کلی</h4>
            <p>کسب تمامی قوانین و مقررات (Terms and Conditions) و دستورالعمل‌ها و آیین‌نامه‌های مرتبط  تجارت ­الکترونیکی و دیگر قوانین و مقررات جمهوری اسلامی ایران را مورد توجه قرار داده و رعایت خواهد کرد. همچنین کاربر نیز ملزم به رعایت تمامی موارد مذکور خواهد بود.</p>
            <h4>ماده ۱ – تعاریف و اصطلاحات</h4>
            <p>اصطلاحاتی که در این قوانین و مقررات به کار رفته است دارای معانی مشروح زیر است:</p>
            <ul>
                <li><strong>کسب:</strong>&nbsp;نرم‌افزاری که به منظور مدیریت مالی کسب و کار در اختیار کاربران قرار می‌گیرد.</li>
                <li><strong>شرکت:</strong>&nbsp;سامان سازان کسب که کلیه حقوق مادی و معنوی این اپلیکیشن متعلق به آن می‌باشد.</li>
                <li><strong>اپلیکیشن:</strong>&nbsp;نرم‌افزاری متعلق به شرکت سامان سازان کسب که به منظور استفاده از خدمات کسب به کاربران ارائه می‌شود. حق استفاده از این نرم‌افزار در قبال پذیرش شرایط قراردادی و سند حاضر به کاربران، در بستر موبایل و وب اعطا می‌گردد.</li>
                <li><strong>کاربر:</strong>&nbsp;شخصی حقیقی است که اپلیکیشن کسب را نصب کرده است، از طریق اینترنت به اپلیکیشن کسب متصل و از خدمات نرم افزاری آن بهرمند می‌شود.</li>
                <li><strong>اطلاعات شخصی:</strong>&nbsp;اطلاعات شخصی دریافتی از کاربر یعنی نام و نام خانوادگی، شماره تلفن همراه و یا اطلاعات اختیاری مانند میزان حوزه‌ی کسب و کار، جنسیت و شهرمحل کار می‌­باشد.</li>
                <li><strong>اطلاعات محرمانه:</strong>&nbsp;اطلاعاتی که کسب حق افشای آن را به شخص ثالث نداشته و صرفا متعلق به کاربر و اپلیکیشن کسب است. این اطلاعات شامل اطلاعات شخصی، اطلاعات مربوط به دارایی‌های کاربر، حساب‌ها، موجودی انبار و لیست اطلاعات مشتریان و پیمانکارها و جزییات تراکنش‌ها و بودجه‌بندی است.</li>
            </ul>
            <h4>ماده ۲ – حساب کاربری</h4>
            <ul>
                <li>کلیه اشخاص حقیقی می‌توانند از خدمات اپلیکیشن کسب بهره‌مند شوند. برای استفاده از خدمات، کاربر مذکور باید شماره موبایل خود را در کسب وارد کرده و از طریق کد تاییدی که به شماره موبایل کاربر ارسال می‌شود اقدام به ثبت‌نام نماید. این قوانین و مقررات (Terms and Conditions) ممکن است در طول زمان تغییر کند و بنابراین استفادۀ مستمر کاربران از اپلیکیشن به معنی پذیرش هرگونه تغییر در قوانین و مقررات (Terms and Conditions) آن است.</li>
                <li>کاربران می‌پذیرند اطلاعات خواسته شده را به صورت صحیح و به‌ روز در اپلیکیشن وارد کنند و مسئول عدم صحت اطلاعات اعلام شده، شخص کاربر می‌باشد.</li>
                <li>کاربران متعهد می‌گردند که صرفا با شماره موبایل متعلق به خود نسبت به ثبت نام در اپلیکیشن کسب اقدام نمایند. در غیر این صورت در برابر شخص ذینفع صاحب شماره موبایل تماما مسئول بوده و شرکت به محض آگاهی از این موضوع نسبت به لغو عضویت کاربر مذکور اقدام خواهد نمود.</li>
                <li>کاربران متعهد می‌گردند که صرفا با شماره مشتری/ حساب/ رمز عبور خود از خدمات کسب بهره مند شوند. در غیر این صورت در برابر شخص ذینفع صاحب شماره مشتری/ حساب/ رمز عبور تماما مسئول بوده و شرکت به محض آگاهی از این موضوع نسبت به لغو عضویت کاربر مذکور اقدام خواهد نمود.</li>
                <li>مسئولیت همه فعالیت‌هایی که از طریق حساب کاربری اشخاص حقیقی و یا حقوقی در اپلیکیشن یا سایر خدمات مربوط به کسب انجام می‌شود به عهدۀ کاربران است.</li>
                <li>کسب برای امنیت بیشتر برای استفاده از نرم‌افزار رمز عبور قرار داده است و مسئولیت حفظ امنیت اطلاعات حساب کاربری از جمله رمز عبور به عهدۀ کاربران است. در صورت مفقود شدن یا سرقت رمز عبور، کاربر مذکور موظف است در اسرع وقت موضوع را به اطلاع شرکت برساند. بدیهی است تا زمانی که اطلاع‌رسانی به شرکت انجام نشده است، مسئولیت همه فعالیت‌هایی که از طریق حساب کاربری مذکور انجام شده و می‌شود به عهدۀ وی خواهد بود.</li>
                <li>کلیه اطلاعات کاربران در سیستم‌های ابری (Cloud) بر اساس شماره موبایل مشتری نگه‌داری می‌شوند.</li>
            </ul>
            <h4>ماده ۳ – استفاده از کسب</h4>
            <ul>
                <li>مسئولیت تامین اینترنت و سخت‌افزار لازم و همچنین پرداخت هزینه‌های مربوط به آنها برای استفاده از خدمات کسب به عهدۀ کاربران است.</li>
                <li>کاربران می‌پذیرند که شرکت ممکن است از طریق ارسال پیامک، ایمیل ، محتوای بنری یا ویدیویی و یا Push Notification به عنوان راه ارتباطی شرکت با وی استفاده کند.</li>
                <li>از اطلاعات محرمانه تنها به منظور ارائه سرویس‌هایی نظیر ثبت اتوماتیک تراکنش‌ها، مقایسه، ارائه پیشنهادات اقتصادی، بودجه‌بندی و بروزرسانی سبد دارایی‌ها استفاده می‌شود. کسب اطلاعات شخصی را به هیچ عنوان با هیچ طرف سومی به اشتراک نمی‌گذارد. این اطلاعات می‌تواند بصورت تجمیعی و ناشناس جهت ایجاد گزارش‌های تحلیلی مورد استفاده قرار گیرد.</li>
            </ul>
            <h4>ماده ۴ - بازاریابی و تجزیه و تحلیل رفتار کاربران کسب</h4>
            <ul>
                <li>کسب اطلاعات شخصی را بدون توافق صریح کاربر، به شخص ثالثی جهت به کارگیری در اهداف بازاریابی آنها نمی‌فروشد یا اجاره نمی‌دهد. ممکن است اطلاعات دریافتی را به منظور پیشرفت و شخصی سازی خدمات، مضامین و تبلیغات خود استفاده کند.</li>
                <li>کسب حق تجزیه و تحلیل‌های آماری رفتار و خصوصیات کاربران به منظور اندازه‌گیری میزان علاقه و استفاده آنها از بخش‌ها و خدمات مختلف پلتفرم و بررسی مشکلات احتمالی و ارتقاء خدمات را برای خود محفوظ می‌شناسد.</li>
            </ul>
            <h4>ماده ۵ – مسئولیت کسب</h4>
            <ul>
                <li>شرکت با تمام امکانات خود تمام تلاش خود را جهت رضایت کاربران و رفع اختلالات ایجاد شده در زمان استفاده از خدمات کسب می‌کند.</li>
                <li>شرکت تلاش خود را در جهت فراهم نمودن امکان استفاده از خدمات نرم‌افزاری خود توسط دستگاه‌های کاربران انجام می‌دهد اما تضمینی نسبت به اجرا شدن آن روی همه دستگاه‌ها یا همه شبکه‌های ارتباطی نخواهد داد.</li>
                <li>تمامی اطلاعات شخصی کاربران به عنوان اطلاعات محرمانه نزد شرکت محافظت شده و دسترسی به آن توسط اشخاص ثالث ممنوع است.</li>
                <li>چنانچه هریک از کاربران کسب در زمان استفاده از خدمات آن با مشکلی مواجه شوند برابر شرایط فوق می‌توانند درخواست پشتیبانی خود را از طریق بخش پشتیبانی در اپلیکیشن یا تلفن و یا ایمیل پشتیبانی مطرح نمایند و شرکت پیگیری لازم و تلاش خود را جهت حل و فصل مشکل بعمل خواهد آورد.</li>
                <li>کسب حق دارد از اطلاعات شخصی کاربر برای نمایش تبلیغات، ارائه پیشنهادهای اقتصادی یا ارائه انواع گزارش‌های تحلیلی و … تنها به خود کاربر استفاده کند.</li>
                <li>کسب حق دارد که از داده‌های پردازش شده و بی‌نام کاربران به نحوی که به هیچ عنوان هویت یا اطلاعات شخصی کاربران قابل تشخیص دادن نباشد استفاده کند و گزارش‌های تجمیعی مبتنی بر این داده‌های پردازش شده را به شخص ثالث ارائه کند.<br></br>به عنوان مثال اطلاعات تجمیعی چندین کاربر در مورد تعداد خرید کسب و کار از یک تامین کننده یا فروشنده یا وارد کننده یا ….. در ماه به صورت نمودار همراه با اطلاعات هزاران کاربر دیگر به صورت تجمیعی و نه انفرادی در اختیار سایر کاربران قرار می­گیرد.</li>
                <li>کسب از فناوری‌هایی استفاده می‌کند که بسیاری از ارائه دهندگان خدمات مدیریت کسب و کار برای حفظ امنیت اطلاعات کاربران استفاده می‌نمایند.کسب خود را نسبت به نشت و درز اطلاعات از منابع و سرور­های خود مسئول می‌­داند. لذا در صورتی که اطلاعات کاربران از هر محل دیگری و یا به دلیل حملات سایبری به زیرساخت­های کلی کشور نشت داشته باشد مسئولیت هرگونه جبران خسارتی را از خود سلب می­‌نماید.</li>
            </ul>
            <h4>ماده ۶ – مسئولیت کاربران</h4>
            <ul>
                <li>مسئولیت همه اقدامات و افعال کاربران که ناشی از عدم رعایت قوانین موضوعه جمهوری اسلامی ایران می‌باشد برعهدۀ شخص متخلف بوده و شرکت هیچ‌گونه مسئولیتی در قبال اعمال و افعال فوق نداشته و نخواهد داشت.</li>
                <li>شرکت می‌تواند ضمن غیرفعال کردن حساب کاربری متخلف، از طریق مراجع ذیصلاح، اقدامات قانونی را علیه نامبرده نسبت به احقاق حقوق قانونی شرکت به عمل آورد.</li>
                <li>هرگونه اقدامی از سوی کاربر که منجر به تحمیل خسارت به شهرت، اعتبار و دارایی‌های شرکت شود تخلف از شرایط قراردادی و قانونی تلقی خواهد شد.</li>
                <li>شرکت این حق را برای خود محفوظ می‌داند که حساب کاربر را مسدود کند و در صورت نیاز، علیه شخص اقدام قانونی انجام دهد و خسارت و حقوق قانونی خود را مطالبه کند.</li>
                <li>کاربر تعهد می‌کند تمامی توضیحات را به صورت کامل قبل از خرید سرویس ( در خصوص خرید بسته‌های غیر رایگان کسب) مطالعه کرده است. بدلیل ارائه کامل توضیحات، پس از پرداخت، بازگشت مبلغ امکان پذیر نخواهد بود.</li>
                <li>کسب این امکان را برای کاربر  فراهم کرده است که تصویر مهر و امضای خود را در فاکتور درج و به صورت آنلاین در قالب فایل پی دی اف در اختیار مشتریان خود قرار دهد. کسب هیچ گونه کنترل و نظارتی بر صحت مهر و امضای کاربر ندارد بنابراین مسئولیت پاسخگویی به هر گونه نقض قوانین و حقوق اشخاص ثالث در این خصوص صرفا بر عهده کاربر است. همچنین کسب هیچ گونه مسئولیتی در برابر سوء استفاده اشخاص ثالث از مهر و امضای کاربر که در فاکتور صادر شده توسط اپلیکیشن کسب درج شده است، ندارد. </li>
                <li>کسب صرفا به عنوان یک اپلیکیشن صدور فاکتور آنلاین برای کاربران خود است و هیچ گونه کنترل و مسئولیتی در خصوص نوع کالا و خدماتی که کاربران با استفاده از کسب برای آن فاکتور صادر می کند، ندارد. </li>
            </ul>
            <h4>ماده ۷ – موارد فنی</h4>
            <ul>
                <li>کاربران حق ندارند هیچگونه تلاشی در جهت استخراج سورس کد (Source Code) نرم‌افزار کسب انجام دهند مگر اینکه اجازۀ چنین کاری به صورت قانونی صادر شده باشد.</li>
                <li>کاربران مجاز نیستند با استفاده از روش‌های Framing یا Mirroring یا روش‌های دیگر نسخۀ دیگری از خدمات کسب را روی سرورهای دیگر ارائه کنند. همچنین کاربر مجاز نیست در نرم‌افزار کسب یا در روش ارائه خدمات تغییر یا اخلالی ایجاد کند.</li>
                <li>کاربران حق ندارند نرم‌افزار یا اسکریپتی با هدف ایندکس‌گذاری، مطالعه یا هر گونه فعالیت داده‌کاوی روی خدمات اجرا کنند.</li>
                <li>کاربران حق ندارند هیچ فعالیتی در راستای حصول دسترسی غیرقانونی و غیر متداول به هیچ بخش از اپلیکیشن کسب انجام دهند.</li>
            </ul>
            <h4>ماده ۸ – قطع خدمات</h4>
            <p>شرکت در شرایطی که به هر دلیل به این نتیجه برسد که حضور یک کاربر می‌تواند برای شرکت یا سایر کاربران خطرناک باشد طبق صلاحدید و تشخیص خود حق حذف دسترسی کاربر مذکور به خدمات کسب را دارد. در این صورت، کاربر حق طرح هیچ‌گونه ادعا و یا اعتراضی را نخواهد داشت</p>
        </div>
    );
}

export default Content;
