import React from "react";
import './Story.css';
import story1 from '../images/story1.png';
import story2 from '../images/story2.png';
import story3 from '../images/story3.png';
import story4 from '../images/story4.png';
import arrowImage from '../images/arrow_back.svg';

const Story = () => {
    return (
        <div className="story-container">
            <h1>داستان یه کوچ راحت از <span className="red">دفتردستک</span> به <span className="green">کسب</span></h1>
            <div className="story">
                <div className="story-item">
                    <img className="story-item" src={story1} />
                    <span className="caption">اپلیکیشن کسب را نصب کنید.</span>
                </div>
                <img className="arrow" src={arrowImage} />
                <div className="story-item">
                    <img className="story-item" src={story2} />
                    <span className="caption">محصولات خود را اضافه کنید.</span>
                </div>
                <img className="arrow" src={arrowImage} />
                <div className="story-item">
                    <img className="story-item" src={story3} />
                    <span className="caption">فاکتور صادر کنید.</span>
                </div>
                <img className="arrow" src={arrowImage} />
                <div className="story-item">
                    <img className="story-item" src={story4} />
                    <span className="caption">سوارِ کسب‌و‌کار خود باشید.</span>
                </div>
            </div>
        </div>
    );
};

export default Story;