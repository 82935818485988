import React from "react";
import './Why.css';

const Why = () => {
    return (<div className="why-container">
        <h1>چرا بسته‌ی رونق؟</h1>
        <p>یکی از مهم‌ترین بخش‌های فروش در هر فروشگاهی، ارتباط مستمر با مشتریان است.
            مدیریت ارتباط با مشتری فرآیندی است که در آن فروشنده با هدف افزایش و رشد فروش، تعاملات خود را با مشتریان مدیریت می‌کند.
            هر چقدر مشتریان و خریداران شما از خدمات و محصولات فروشگاه شما اطلاعات دقیق‌ و کامل‌ و به‌روز‌تری داشته باشند، انتخاب و تصمیم‌ به خرید برایشان آسوده‌تر خواهد بود.
            <b> هدف از ارائه‌ی خدمات ویژه در بسته‌ی رونق:</b>
        </p>
        <div className="aims">
            <div className="aim-item">
                <span className="aim-icon">&#xf201;</span>
                <p className="aim-description">افزایش فروش</p>
            </div>
            <div className="aim-item">
                <span className="aim-icon">&#xf2b5;</span>
                <p className="aim-description">تبدیل مشتریان گذری به مشتری وفادار</p>
            </div>
            <div className="aim-item">
                <span className="aim-icon">&#xf4c0;</span>
                <p className="aim-description">تبدیل خریدار بالقوه به مشتری</p>
            </div>
            <div className="aim-item">
                <span className="aim-icon">&#xf086;</span>
                <p className="aim-description">ایجاد ارتباط مستمر با مشتریان فعلی فروشگاه</p>
            </div>
        </div>
    </div>
    );
};

export default Why;