import React, { useEffect } from 'react';
import './RonaghPage.css';
import Navbar from '../components/Navbar';
import Ronagh from '../components/ronagh/Ronagh';
import Download from '../components/Download';
import Package from '../components/ronagh/Package';
import Footer from '../components/Footer';
import RoadMap from '../components/ronagh/RoadMap';
import Why from '../components/ronagh/Why';
import Content from '../components/ronagh/Content';

const RonaghPage = () => {
    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <div className='ronagh-page-container'>
            <Navbar />
            <Ronagh hasMore={false} />
            <Why />
            <Content />
            <RoadMap />
            <Package />
            <Download />
            <Footer />
        </div>
    );
};

export default RonaghPage;