import './TermsAndConditionsPage.css';
import Header from '../components/Header';
import Content from '../components/terms_and_conditions/Content';
import Footer from '../components/Footer';

const TermsAndConditionsPage = () => {
    return (
        <div className='terms-conds-page-container'>
            <Header />
            <Content/>
            <Footer />
        </div>
    );
}

export default TermsAndConditionsPage;
