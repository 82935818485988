import './App.css';
import Download from './components/Download';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Intro from './components/Intro';
import Navbar from './components/Navbar';
import Partners from './components/Partners';
import Ronagh from './components/ronagh/Ronagh';
import Slideshow from './components/SlideShow';
import Story from './components/Story';
import Video from './components/Video';

function App() {
    return (
        <div className="App">
            <Navbar />
            <Hero />
            <Download />
            <Intro />
            <Video />
            <Slideshow />
            <Ronagh />
            <Story />
            <FAQ />
            <Partners />
            <Footer />
        </div>
    );
}

export default App;
