import React from 'react';
import './Partners.css';
import khalayegh from '../images/logo-khalayegh.png';
import persiafava from '../images/logo-persiafava.png';
import tirajeh from '../images/logo-tirajeh.png';

const Partners = () => {
    return (
        <div className="partners">
            <h2>همکاران و همراهان کسب</h2>
            <div className='partners-list'>
                <a href='https://cidfund.ir/' target='_blank'>
                    <img src={khalayegh} alt='Sanaye-e Khalayegh logo' />
                </a>
                <a href='https://persiafava.com/' target='_blank'>
                    <img src={persiafava} alt='Persiafava logo' />
                </a>
                <a href='http://tirajehgroup.com/' target='_blank'>
                    <img src={tirajeh} alt='Tirajeh logo' />
                </a>
            </div>
        </div>
    );
};

export default Partners;
