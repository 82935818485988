import React from "react";
import './Intro.css';
import screenshot from '../images/screenshot0.png';

const Intro = () => {
    return (
        <div className='intro-container' id="intro">
            <div className='intro'>
                <img className='intro-image' src={screenshot} alt='Kasb application screenshot' />
                <div className='intro-text'>
                    <p>
                        اپلیکیشن کسب برای کسب‌وکارهای کوچک و خانگی طراحی شده تا با نظم دادن به فرایند فروش و دریافت گزارش عمکرد آنلاین و لحظه‌ای بتوانند پیش‌بینی درستی از بازار داشته باشند و در سریعترین زمان ممکن به بیشترین سود دست پیدا کنند.
                    </p>
                    <h2 className='intro-hint'>نظم با سوددهی کار و کسب ارتباط مستقیم دارد.</h2>
                </div>
            </div>
        </div>
    );
};

export default Intro;