import React from "react";
import './Ronagh.css';
import RonaghImage from '../../images/ronagh.png';
import { Link } from "react-router-dom";

const Ronagh = ({ hasMore = true }) => {
    return (
        <div className="ronagh-container" id='ronagh'>
            <img alt='Ronagh' src={RonaghImage} />
            <div className="ronagh-info">
                <p>
                    کسب طراحى شده تا کسب‌وکارهای کوچک و خانگى با نظم دادن به فرآیند فروش و دریافت گزارش عملکرد آنلاین و لحظه‌ای بتوانند پیش‌بینی درستی از بازار داشته باشند، در سریع‌ترین زمان ممکن به بیشترین سود دست پیدا کنند و در بازاریابی و مسیر هوشمند سازی کسب‌وکار در کنار فروشندگان باشد.

                    ما در کسب علاوه بر نظم دادن به کسب‌وکار شما، به رشد فروش‌تان می‌اندیشیم.

                    علاوه بر خدمات و امکانات فعلی که به صورت رایگان در دسترس کاربران قرار گرفته، سرویس‌های ویژه به منظور افزایش و رشد فروش شما فروشنده گرامی، در قالب بسته‌ی رونق کسب‌وکار در دسترس شما قرار می‌گیرد.
                </p>
                {hasMore &&
                    <Link to="/ronagh">اطلاعات بیش‌تر</Link>
                }
            </div>
        </div>
    );
};

export default Ronagh;