import React from "react";
import './RoadMap.css';

const RoadMap = () => {
    return (
        <div className="roadmap-container">
            <h1>نقشه راه بسته‌ی رونق</h1>
            <div className="roadmap">
                <div className="roadmap-item">
                    <span className="icon">&#xf295;</span>
                    <h2>گزارش پیشرفته <sup>(به‌زودی)</sup></h2>
                    <p>چشم‌انداز مالی درست به رشد فروشگاه شما کمک اساسی می‌کند و با آمار دقیق شما می‌توانید  به چشم انداز واضح تری از آینده کسب‌و‌کار خود برسید.</p>
                </div>
                <div className="roadmap-item">
                    <span className="icon">&#xf4ff;</span>
                    <h2>افزودن فروشنده و ویزیتور <sup>(به‌زودی)</sup></h2>
                    <p>با بزرگ‌تر شدن فروشگاهتان شما با تعامل با همکاران خود در بستر نرم‌افزار کسب، کارایی فروشگاه خود را بهینه کنید.</p>
                </div>
                <div className="roadmap-item">
                    <span className="icon rotate-and-mirror">&#xf080;</span>
                    <h2>جشنواره فروش <sup>(به‌زودی)</sup></h2>
                    <p>با قابلیت برگزاری جشنواره فروش شما می‌توانید با قراردادن تخفیف روی برخی از اجناس خود در بازه‌‌ی زمانی مشخص، مشتریان خود را به خرید از فروشگاهتان ترغیب کنید.</p>
                </div>
            </div>
        </div>
    );
}

export default RoadMap;