import './Content.css';

const Content = () => {
    return (
        <div className="release-notes-content-container">
            <h4>نسخه ۳.۳.۰</h4>
            <ul>
                <li>امکان ویرایش و حذف سفارش‌های خرید و فاکتورهای فروش</li>
                <li>افزودن قابلیت یادداشت به سفارش‌های خرید</li>
                <li>امکان ثبت برگشت از خرید</li>
                <li>بهبود تجربه کاربری و رفع اشکالات گزارش‌شده</li>
            </ul>
            <h4>نسخه ۳.۲.۸</h4>
            <ul>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۳.۲.۷</h4>
            <ul>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۳.۲.۵</h4>
            <ul>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۳.۲.۴</h4>
            <ul>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۳.۲.۳</h4>
            <ul>
                <li>حذف تور آشنایی با اپلیکیشن</li>
            </ul>
            <h4>نسخه ۳.۲.۱</h4>
            <ul>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۳.۲.۰</h4>
            <ul>
                <li>امکان تعریف واحدهای اعشاری برای محصولات</li>
                <li>تسهیل روند افزودن محصول و مدیریت موجودی</li>
            </ul>
            <h4>نسخه ۳.۱.۰</h4>
            <ul>
                <li>امکان ثبت یادداشت برای فاکتور ها</li>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۳.۰.۰</h4>
            <ul>
                <li>اضافه شدن قابلیت مدیریت موجودی محصولات فروشگاه</li>
                <li>اضافه شدن نقش تامین کننده در منوی افراد</li>
                <li>اضافه شدن قابلیت ثبت سفارش خرید</li>
                <li>تغییرات در صفحه محصول و محاسبه میانگین قیمت خرید و فروش</li>
                <li>امکان تعریف چندین فی برای هر محصول</li>
                <li>امکان ثبت برگشت از فروش برای فاکتور ها</li>
            </ul>
            <h4>نسخه ۲.۶.۲</h4>
            <ul>
                <li>تغییر درصد مالیات با توجه به قوانین مالیاتی کشور به ده درصد برای فاکتور های ثبت شده در سال ۱۴۰۳</li>
            </ul>
            <h4>نسخه ۲.۶.۱</h4>
            <ul>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۲.۶.۰</h4>
            <ul>
                <li>افزودن قابلیت اشتراک لیست محصولات فروشگاه به صورت آنلاین در قالب سرویس “لیست قیمت آنلاین” * بسته رونق*</li>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۲.۳.۱</h4>
            <ul>
                <li>رفع مشکلات گزارش شده</li>
            </ul>
            <h4>نسخه ۲.۳.۰</h4>
            <ul>
                <li>افزودن بخش هزینه‌ها جهت ثبت هزینه‌های فروشگاه</li>
                <li>بهبود رابط‌ کاربری داشبورد</li>
                <li>بهینه‌سازی و بهبود عملکرد نرم‌افزار</li>
            </ul>
            <h4>نسخه ۲.۰.۰</h4>
            <ul>
                <li>اصلاح و بهینه سازی روند صدور فاکتور</li>
                <li>اضافه کردن تم تیره</li>
                <li>افزودن قابلیت تعریف مهر وامضا برای فروشگاه</li>
                <li>افزودن امکانات امنیتی نظیر تاریخ انقضا و پسورد برای لینک اشتراک گذاری فاکتور</li>
                <li>امکان تعریف حوزه فعالیت</li>
                <li>بروزرسانی لیست واحد محصولات</li>
                <li>افزودن امکان تعریف تخفیف جزء برای هر کالا</li>
                <li>افزودن امکان تعریف مالیات بر ارزش افزوده برای فاکتور ها</li>
                <li>تغییرات ظاهری جزئی جهت بهبود تجربه کاربری</li>
                <li>افزودن تنظیمات شخصی سازی دریافت اعلان ها</li>
                <li>تغییر فایل فاکتور و بهینه سازی برای چاپ و مشاهده در موبایل</li>
                <li>افزودن امکان تعریف کانال‌های فروش</li>
            </ul>
            <h4>نسخه ۱.۱.۲</h4>
            <ul>
                <li>رفع مشکلات گزارش شده</li>
            </ul>
            <h4>نسخه ۱.۱.۱</h4>
            <ul>
                <li>شناسایی و برطرف کردن مشکلات گزارش شده در سیستم نظارت بر عملکرد فنی</li>
            </ul>
            <h4>نسخه ۱.۱.۰</h4>
            <ul>
                <li>اضافه کردن بخش مشتریان، جهت مدیریت مشتریان و ثبت مشتریان جدید</li>
                <li>تسریع روند صدور فاکتور</li>
                <li>تعریف وضعیت پیش فاکتور</li>
                <li>اضافه کردن بخش اعلان‌ها</li>
                <li>بهینه‌سازی زیرساخت محاسبات</li>
                <li>اعمال تغییرات و بهبود فضای بصری اپلیکیشن</li>
            </ul>
        </div>
    );
}

export default Content;
