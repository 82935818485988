import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: "آیا امکان تعریف چند فروشگاه جداگانه با یک اکانت وجود دارد؟",
            answer: "بله، شما می‌توانید روی یک شماره موبایل تا ده فروشگاه ایجاد کنید."
        },
        {
            question: "در صورتی‌ که یک محصول رو حذف کنیم، اون محصول از گزارشات هم حذف می‌شه؟",
            answer: "خیر، زمانی‌که فاکتور توسط کاربر ثبت شد، تمام اطلاعات ذخیره شده در اون فاکتور شامل قیمت، نام مشتری، محصول و … ثبت شده و با تغییر اطلاعات محصول یا مشتری، اطلاعات فاکتور ذخیره شده تغییری نخواهد کرد."
        },
        {
            question: "اگر نخوام با شما کار کنم، اطلاعاتم رو خروجی می‌دی؟",
            answer: "بله، فقط کافیست به تیم پشتیبانی کسب اعلام کنید تا کلیه فعالیت‌های شما و اطلاعاتی که ذخیره شده رو به صورت خروجی Excel دریافت کنید. بخش گزینه دریافت خروجی فایل به صورت لحظه‌ای ، به زودی در اپلیکیشن قرار خواهد گرفت."
        },
        {
            question: "آیا ارسال فاکتور به صورت اس‌ام‌اس به مشتری امکان پذیر هست؟",
            answer: "لینک فاکتور صادر شده به صورت متن قابل کپی شدن، در فرمت HTML برای ارسال SMS به مشتریان در دسترس شما قرار دارد. همچنین شما می‌توانید فاکتور صادر شده رو و با فرمت PDF در پیامرسان‌های مختلف با مشتریان‌تان به اشتراک بگذارید."
        }
    ];

    const toggleFAQ = index => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-container" id='faq'>
            <h2>سوالات متداول</h2>
            {faqs.map((faq, index) => (
                <div key={index} className={`faq-item ${index === faqs.length - 1 ? 'last-item' : ''}`}>
                    <div className="faq-question" onClick={() => toggleFAQ(index)}>
                        <h4>{faq.question}</h4>
                        <span>{openIndex === index ? '-' : '+'}</span>
                    </div>
                    <div className={`faq-answer ${openIndex === index ? 'open' : ''}`}>{faq.answer}</div>
                </div>
            ))}
        </div>
    );
};

export default FAQ;
