import React, { useState } from "react";
import './Video.css';
import videoItem1 from '../images/video-item-1.png';
import videoItem2 from '../images/video-item-2.png';
import videoItem3 from '../images/video-item-3.png';
import videoItem4 from '../images/video-item-4.png';
import videoCover from '../images/video-cover.png';
import videoItemsBar from '../images/video-items-bar.png';
import VideoPlayerModal from "./VideoPlayerModal";

const Video = () => {
    const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false);

    const openModal = () => setShowVideoPlayerModal(true);
    const closeModal = () => setShowVideoPlayerModal(false);

    return (
        <>
            <div className="video-container">
                <div
                    className="video"
                    onClick={openModal}
                >
                    <img src={videoCover} alt="Video cover" />
                </div>
                <div className="video-items-bar">
                    <img src={videoItemsBar} alt='App characteristics' />
                </div>
                <div className="video-items">
                    <div className="video-item video-item-border-radius-left">
                        <img src={videoItem1} alt="Structured" />
                    </div>
                    <div className="video-item video-item-border-radius-right">
                        <img src={videoItem2} alt="Fast" />
                    </div>
                    <div className="video-item video-item-border-radius-left">
                        <img src={videoItem3} alt="Accurate" />
                    </div>
                    <div className="video-item video-item-border-radius-right">
                        <img src={videoItem4} alt="Safe" />
                    </div>
                </div>
            </div>
            <VideoPlayerModal show={showVideoPlayerModal} close={closeModal} />
        </>
    );
};

export default Video;