import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RonaghPage from './pages/RonaghPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ReleaseNotesPage from './pages/ReleaseNotesPage';
import NotFoundPage from './pages/NotFoundPage';
import { Bounce, ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            pauseOnHover
            draggable
            newestOnTop
            pauseOnFocusLoss
            rtl
            theme="dark"
            transition={Bounce}
        />
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/ronagh" element={<RonaghPage />} />
                <Route path="/terms-conditions" element={<TermsAndConditionsPage/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
                <Route path="/release-notes" element={<ReleaseNotesPage/>} />
                <Route path="*" element={<NotFoundPage />}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
