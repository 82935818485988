import React from "react";
import './Content.css';
import ronaghImg0 from '../../images/ronagh0.png';
import ronaghImg1 from '../../images/ronagh1.png';
import ronaghImg2 from '../../images/ronagh2.png';
import ronaghImg3 from '../../images/ronagh3.png';

const Content = () => {
    return (
        <div className="content-container">
            <h2>لیست قیمت آنلاین</h2>
            <div className='prices-list'>
                <img src={ronaghImg0} />
                <p>
                    لیست قیمت آنلاین این امکان را برای شما کاربران اپلیکیشن کسب فراهم کرده تا  لیست قیمت آنلاین خود را در کسب بسازید و در قالب یک لینک، آن‌را با مشتریان خود به اشتراک بگذارید.

                    بدون نیاز به صرف زمان، تنها با یک کلیک، لیست موجودی فروشگاه، همراه با قیمت‌های به‌روز کالاهای خود را در کسب بسازید.

                    اطلاعات مورد نیاز فروشگاه خود را به آن اضافه کنید و تمام مشتریان کسب و کارتان را از قیمت‌ و موجودی کالای فروشگاه‌تان با خبر کنید.                </p>
            </div>
            <h2>ویژگی‌های لیست قیمت آنلاین</h2>
            <div className="prices-features">
                <div className="prices-features-item">
                    <img src={ronaghImg1} />
                    <h3>یه لیست شُسته رُفته</h3>
                    <p>
                        تنها با یک کلیک، لیست به‌روز موجودی فروشگاهتان را در کسب بسازید و با مشتریان خود به اشتراک بگذارید.
                    </p>
                </div>
                <div className="prices-features-item">
                    <img src={ronaghImg2} />
                    <h3>یه سفارش بی‌چند و چون</h3>
                    <p>پس از مشاهده لیست قیمت آنلاین، مشتریان از طریق راه‌های ارتباطی فروشگاه، با شما تماس می‌گیرند.</p>
                </div>
                <div className="prices-features-item">
                    <img src={ronaghImg3} />
                    <h3>آمار دقیق بازدید</h3>
                    <p>گزارش تعداد بازدیدکنندگان لیست قیمت آنلاین خود را به صورت لحظه‌ای مشاهده کنید.</p>
                </div>
            </div>


        </div >
    );
};

export default Content;