import React from "react";
import './Download.css';
import android from '../images/android.svg';
import myket from '../images/myket.svg';
import bazaar from '../images/bazaar.svg';
import sibapp from '../images/sibapp.svg';
import anardooni from '../images/anardooni.svg';
import globe from '../images/globe.svg';
import apple from '../images/apple.svg';
import downloadPng from '../images/download.svg';

const Download = () => {
    return (
        <div id='download' className="download-container">
            <div className="inner-download-container">
                <h2>دریافت نرم‌افزار کسب</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start'}}>
                        <div style={{ display: 'flex', height: '55px', alignItems: 'center', justifyContent: 'start', gap: '16px' }}>
                            <img src={android} alt='Android' style={{ paddingBottom: '4px', width: '30px' }}/>
                            <h3>نسخه اندروید</h3>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'start', gap: '16px', marginRight: '-8px' }}>
                        <a className="store-button" href="https://kasb-co.com/dl/kasb.apk" target="_blank" rel="noreferrer">
                                <label>دریافت مستقیم</label>
                                <img src={downloadPng} alt='CafeBazaar' />
                            </a>
                            <a className="store-button" href="https://cafebazaar.ir/app/com.kasbcompany.android" target="_blank" rel="noreferrer">
                                <label>دریافت از بازار</label>
                                <img style={{width: '24px'}} src={bazaar} alt='CafeBazaar' />
                            </a>
                            <a className="store-button" href="https://myket.ir/app/com.kasbcompany.android" target="_blank" rel="noreferrer">
                                <label>دریافت از مایکت</label>
                                <img src={myket} alt='Myket' style={{ backgroundColor: 'black', borderRadius: '50px' }} />
                            </a>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '8px' }}>
                        <div style={{ display: 'flex', height: '48px', alignItems: 'center', justifyContent: 'start', gap: '16px' }}>
                            <img src={apple} alt='Apple' style={{ paddingBottom: '4px', width: '24px' }}/>
                            <h3>نسخه آی او اس</h3>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'start', gap: '16px', marginRight: '-8px' }}>
                            {/* TODO: iApps */}
                            <a className="store-button" href="https://sibapp.com/applications/KasbInvoicingApp" target="_blank" rel="noreferrer">
                                <label>دریافت از سیب‌اپ</label>
                                <img src={sibapp} alt='SibApp' />
                            </a>
                            <a className="store-button" href="https://anardoni.com/ios/app/comkasbcompanyios" target="_blank" rel="noreferrer">
                                <label>دریافت از اناردونی</label>
                                <img style={{width: '24px'}} src={anardooni} alt='Anardooni' />
                            </a>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '8px' }}>
                        <div style={{ display: 'flex', height: '48px', alignItems: 'center', justifyContent: 'start', gap: '16px' }}>
                            <img src={globe} alt='Web' style={{ paddingBottom: '4px', width: '24px' }}/>
                            <h3>نسخه وب</h3>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '16px', marginRight: '-8px' }}>
                            <a className="store-button" href="https://web.kasb-co.com" target="_blank" rel="noreferrer">
                                <label>ورود به وب‌اپلیکیشن</label>
                                <img src={globe} alt='WebApp' style={{width: '24px'}} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Download;